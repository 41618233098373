<template>
  <div id="app">
    
    <div 
      v-if="!isAdminDashboard && !isManagerDashboard" 
      class="fixed-container" 
      :class="{ 'has-banner': !isBannerClosed && !isBusinessDashboard }"
    >
      <white-paper-banner v-if="!isBusinessDashboard" @banner-closed="handleBannerClose" />
      <header v-if="showHeader" :class="{ 'hero-view': isHeroView, 'cta-view': isCTAView, 'pricing-view': isPricingView, 'assessment-view': isAssessmentView }">
        <div class="logo-link" :class="{ 'hidden': isHeroView || isCTAView }" @click="navigateToHero">
          <img src="@/assets/logo.png" alt="Victory AI Logo" class="logo">
        </div>
        <button 
          class="hamburger-menu" 
          :class="{ 'open': isMobileMenuOpen }" 
          @click="toggleMobileMenu" 
          aria-label="Toggle menu"
        >
          <span></span>
          <span></span>
          <span></span>
        </button>
        <nav :class="{ 'centered': isHeroView || isCTAView, 'mobile-menu-open': isMobileMenuOpen }">
         
          <router-link to="/how-it-works" @click="closeMobileMenu">How it Works</router-link>
          <!-- <router-link to="/pricing" @click="closeMobileMenu">Pricing</router-link> -->
          <router-link to="/why-victory" @click="closeMobileMenu">Why Victory</router-link>
          
          <a href="#" @click.prevent="showWhitePaperModal(); closeMobileMenu()">White Paper</a><router-link to="/get-started" @click="closeMobileMenu">Get Started</router-link>

          <router-link to="/schedule" @click="closeMobileMenu">Schedule Call</router-link>
          <!-- <router-link to="/assessment" @click="closeMobileMenu">Take Assessment</router-link> -->
          
          <router-link to="/login" class="login-btn" @click="closeMobileMenu">Login</router-link>
        </nav>
      </header>
    </div>
    <div class="route-container" 
         :class="{
           'has-banner': showBanner,
           'business-dashboard-page': isBusinessDashboard,
           'admin-dashboard-page': isAdminDashboard,
           'manager-dashboard-page': isManagerDashboard
         }">
      <transition name="fade" mode="out-in">
        <router-view v-slot="{ Component }">
          <component :is="Component" 
            ref="currentView"
            @update-hero-view="updateHeroView" 
            @update-cta-view="updateCTAView"
            @update-pricing-view="updatePricingView"
            @update-scroll-progress="updateScrollProgress"
          />
        </router-view>
      </transition>
    </div>
    <white-paper-modal 
      :show="showModal" 
      @close="showModal = false"
      @email-submitted="handleEmailSubmitted"
    />
    <div 
      class="mobile-menu-overlay" 
      :class="{ 'active': isMobileMenuOpen }" 
      @click="closeMobileMenu"
    ></div>
    <mobile-menu 
      :is-open="isMobileMenuOpen"
      @close="closeMobileMenu"
      @show-white-paper="showWhitePaperModal"
    />
    <microphone-error-modal 
      :show="showMicrophoneError"
      @close="showMicrophoneError = false"
    />
  </div>
</template>

<script>
import { useRouter } from 'vue-router'
import { mapState, mapGetters, mapActions } from 'vuex'
import WhitePaperBanner from '@/components/WhitePaperBanner.vue'
import WhitePaperModal from '@/components/WhitePaperModal.vue'
import MobileMenu from '@/components/MobileMenu.vue'
import MicrophoneErrorModal from '@/components/MicrophoneErrorModal.vue'

export default {
  name: 'App',
  components: {
    WhitePaperBanner,
    WhitePaperModal,
    MobileMenu,
    MicrophoneErrorModal
  },
  setup() {
    const router = useRouter()
    return { router }
  },
  data() {
    return {
      isHeroView: false,
      isCTAView: false,
      isPricingView: false,
      isAssessmentView: false,
      scrollProgress: 0,
      showModal: false,
      isMobileMenuOpen: false,
      showMicrophoneError: false,
    }
  },
  created() {
    this.updateCurrentRoute(this.$route);
    this.updateViewState(this.$route);
  },
  watch: {
    $route(to, from) {
      this.updateCurrentRoute(to);
      this.updateViewState(to);
    }
  },
  computed: {
    ...mapState({
      isHeroView: state => state.isHeroView,
      isCTAView: state => state.isCTAView,
      isPricingView: state => state.isPricingView,
      isAssessmentView: state => state.isAssessmentView,
      isBannerClosed: state => state.whitePaperBannerClosed,
      currentSection: state => state.currentSection
    }),
    ...mapGetters(['showHeader']),
    shouldShowHeader() {
      const hiddenHeaderRoutes = ['business-dashboard', 'admin-dashboard', 'dashboard', 'manager-dashboard'];
      return !hiddenHeaderRoutes.includes(this.$route.name);
    },
    shouldShowLogo() {
      return !(
        this.$route.name === 'home' && 
        (this.currentSection === 'hero' || this.currentSection === 'cta')
      )
    },
    isBusinessDashboard() {
      return this.$route.path === '/business-dashboard';
    },
    isAdminDashboard() {
      return this.$route.path === '/admin-dashboard';
    },
    isManagerDashboard() {
      return this.$route.path === '/manager-dashboard';
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
    
    // Global error handler for unhandled promise rejections
    window.addEventListener('unhandledrejection', (event) => {
      if (event.reason && event.reason.name === 'NotFoundError') {
        event.preventDefault();  // Prevent the error from showing in console
        this.showMicrophoneError = true;
        
        // Find and disable the widget button
        const widgetButton = document.querySelector('button[title="Talk to an AI Agent"]');
        if (widgetButton) {
          widgetButton.disabled = true;
          setTimeout(() => {
            widgetButton.disabled = false;
          }, 100);
        }
      }
    });
    
    // Override getUserMedia globally
    const originalGetUserMedia = navigator.mediaDevices.getUserMedia;
    navigator.mediaDevices.getUserMedia = async function(constraints) {
      try {
        return await originalGetUserMedia.call(this, constraints);
      } catch (error) {
        if (error.name === 'NotFoundError') {
          window.app.$data.showMicrophoneError = true;
          throw error;
        }
        throw error;
      }
    };
    
    // Store reference to Vue instance
    window.app = this;
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll)
    window.removeEventListener('unhandledrejection')
    delete window.app;  // Clean up global reference
  },
  methods: {
    ...mapActions(['updateCurrentRoute']),
    updateViewState(route) {
      this.isHeroView = route.name === 'home';
      this.isCTAView = false;
      this.isPricingView = route.name === 'pricing';
      this.isAssessmentView = route.name === 'assessment';
    },
    updateHeroView(value) {
      this.isHeroView = value;
    },
    updateCTAView(value) {
      this.isCTAView = value;
    },
    updatePricingView(value) {
      this.isPricingView = value;
    },
    updateScrollProgress(value) {
      this.scrollProgress = value;
    },
    navigateToHero() {
      if (this.$route.name !== 'home') {
        this.router.push({ name: 'home' })
      } else if (this.$refs.currentView && this.$refs.currentView.scrollToTop) {
        this.$refs.currentView.scrollToTop()
      }
    },
    handleBannerClose() {
      this.$store.dispatch('closeWhitePaperBanner')
    },
    showWhitePaperModal() {
      this.showModal = true
    },
    async handleEmailSubmitted(email) {
      try {
        // Here you would typically make an API call to handle the email submission
        // console.log('Email submitted:', email)
        // You could also dispatch a Vuex action here to handle the email submission
      } catch (error) {
        console.error('Error submitting email:', error)
      }
    },
    toggleMobileMenu() {
      this.isMobileMenuOpen = !this.isMobileMenuOpen;
    },
    closeMobileMenu() {
      this.isMobileMenuOpen = false;
    },
    handleScroll() {
      if (this.$route.name === 'home') {
        const scrollPosition = window.scrollY
        const viewportHeight = window.innerHeight
        
        if (scrollPosition > viewportHeight * 0.5) {
          this.$store.dispatch('closeWhitePaperBanner')
        }
      }
    },
  }
}
</script>

<style>
:root {
  --accent-victory-green: #093927;
  --accent-sunny-yellow: #ffcb78;
  --primary-background-cream: #FCF8F2;
  --secondary-background-sky-blue: #79CCE1;
  --main-text-color: #393120;
  --header-height: 80px; /* Adjust this value to match your actual header height */
}

#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--main-text-color);
  line-height: 1.6;
  background-color: var(--primary-background-cream);
  transition: padding-top 0.3s ease;
}

#app.has-banner {
  padding-top: 60px; /* Adjust based on your banner height */
}

@media (max-width: 768px) {
  #app.has-banner {
    padding-top: 120px; /* Adjust for mobile banner height */
  }
}

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  height: 80px;
  overflow: hidden;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: transparent;
}

/* Add desktop-only styles */
@media (min-width: 769px) {
  header {
    background: linear-gradient(90deg, #fcf8f2, #79cce1);
  }
}

/* Update mobile styles with higher specificity */
@media (max-width: 768px) {
  .fixed-container header,
  header.hero-view,
  header.cta-view,
  header.pricing-view,
  header.assessment-view {
    background: transparent !important;
    height: 60px;
    padding: 0 20px;
    justify-content: flex-start;
  }

  /* Hide logo on mobile */
  .logo-link {
    display: none !important;
  }

  /* Remove any background from fixed container */
  .fixed-container {
    background: none !important;
  }

  /* Force header transparency */
  #app .fixed-container header {
    background: transparent !important;
    background-color: transparent !important;
    background-image: none !important;
  }

  /* Additional header override */
  header {
    background: transparent !important;
    background-color: transparent !important;
    background-image: none !important;
  }
}

.logo-link {
  text-decoration: none;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease;
  cursor: pointer;
}

.logo-link.hidden {
  opacity: 0;
}

.logo {
  height: 50px;
  width: auto;
  transition: all 0.5s ease;
}

nav {
  display: flex;
  align-items: center;
  transition: all 0.5s ease;
  flex-wrap: nowrap;
  gap: 5px;
}

nav.centered {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;
  width: auto;
}

nav a {
  font-weight: bold;
  color: var(--accent-victory-green);
  text-decoration: none;
  padding: 8px 16px;
  white-space: nowrap;
}

nav a.router-link-exact-active {
  color: var(--accent-victory-green);
  background-color: var(--accent-sunny-yellow);
  padding: 8px 16px;
  border-radius: 20px;
}

.login-btn {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
  border: none;
  border-radius: 20px;
  padding: 8px 16px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  text-decoration: none;
}

.login-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

section {
  scroll-snap-align: start;
  min-height: 100vh;
  padding-top: 80px; /* Adjust this value based on your header height */
  box-sizing: border-box;
}

h1, h2 {
  margin-bottom: 20px;
  color: var(--accent-victory-green);
}

p {
  max-width: 800px;
  /* text-align: center; */
}

button {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

button:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
  transform: scale(1.05);
}

/* Hide scrollbar for Chrome, Safari and Opera */
::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
body {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1.6s cubic-bezier(0.4, 0, 0.2, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.route-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding-top: 80px; /* Height of header */
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  z-index: 1;
}

.route-container.business-dashboard-page,
.route-container.admin-dashboard-page,
.route-container.manager-dashboard-page {
  padding-top: 0 !important;
}

.route-container.has-banner {
  padding-top: 140px; /* Height of header + banner */
}

.route-container.business-dashboard-page.has-banner,
.route-container.admin-dashboard-page.has-banner,
.route-container.manager-dashboard-page.has-banner {
  padding-top: 0 !important;
}

@media (max-width: 768px) {
  .route-container.has-banner:not(.business-dashboard-page):not(.admin-dashboard-page):not(.manager-dashboard-page) {
    padding-top: 200px; /* Adjust for mobile heights */
  }
}

.fixed-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  background: transparent;
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.fixed-container.has-banner {
  transform: translateY(0);
}

.fixed-container:not(.has-banner) {
  transform: translateY(0); /* Changed from -60px to 0 */
}

.route-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-y: auto;
  padding-top: 80px; /* Height of header */
  transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  background: linear-gradient(90deg, #fcf8f2, #79cce1);
  z-index: 1;
}

.route-container.has-banner {
  padding-top: 140px; /* Height of header + banner */
}

@media (max-width: 768px) {
  .route-container.has-banner {
    padding-top: 200px; /* Adjust for mobile heights */
  }
}

@media (max-width: 768px) {
  #app {
    font-size: 14px; /* Slightly smaller base font size for mobile */
  }

  header {
    background: none !important;
    height: 60px;
    padding: 0 20px;
    justify-content: flex-start;
    position: relative;
  }

  nav {
    flex-direction: column;
    gap: 10px;
  }

  .nav-links {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  button {
    padding: 8px 16px; /* Smaller padding for buttons on mobile */
    font-size: 0.9rem;
  }

  section {
    padding-top: 60px; /* Adjust for smaller header height */
  }

  h1 {
    font-size: 2rem;
  }

  h2 {
    font-size: 1.5rem;
  }

  p {
    font-size: 1rem;
    max-width: 100%;
    padding: 0 15px;
  }

  .route-container {
    padding-top: 60px; /* Adjust for smaller header height */
  }

  .route-container.has-banner {
    padding-top: 120px; /* Adjust for mobile header + banner height */
  }
}

/* Mobile Menu Styles */
.hamburger-menu {
  display: none; /* Hidden by default on desktop */
  flex-direction: column;
  justify-content: space-around;
  width: 30px;
  height: 25px;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  z-index: 1001; /* Ensure it's above the nav menu */
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}

.hamburger-menu span {
  width: 30px;
  height: 3px;
  background: var(--accent-victory-green);
  border-radius: 10px;
  transition: all 0.3s linear;
  position: relative;
  transform-origin: 1px;
}

/* Add hamburger animation */
.hamburger-menu.open span:first-child {
  transform: rotate(45deg);
}

.hamburger-menu.open span:nth-child(2) {
  opacity: 0;
}

.hamburger-menu.open span:last-child {
  transform: rotate(-45deg);
}

@media (max-width: 768px) {
  .hamburger-menu {
    display: flex;
  }

  nav {
    position: fixed;
    top: 60px; /* Height of mobile header */
    left: 100%; /* Change from right to left for better performance */
    width: 100%;
    height: calc(100vh - 60px);
    background: var(--primary-background-cream);
    flex-direction: column;
    padding: 20px;
    transition: transform 0.3s ease; /* Change transition property */
    z-index: 1000;
    align-items: center;
  }

  nav.mobile-menu-open {
    transform: translateX(-100%); /* Slide in from right */
    background: var(--primary-background-cream);
  }

  nav a {
    padding: 15px 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid rgba(9, 57, 39, 0.1);
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.3s ease, transform 0.3s ease;
  }

  nav.mobile-menu-open a {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
  }

  header {
    justify-content: flex-start; /* Align logo to left */
    padding: 10px 20px;
    position: relative; /* For absolute positioning of hamburger */
  }

  .logo {
    height: 40px;
  }

  /* Add overlay when menu is open */
  .mobile-menu-overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 999;
  }

  .mobile-menu-overlay.active {
    display: block;
  }
}

@media (max-width: 768px) {
  /* Hide desktop nav on mobile */
  header nav {
    display: none;
  }

  /* Show hamburger menu */
  .hamburger-menu {
    display: flex;
    position: absolute;
    right: 20px;
    top: 50%;
    transform: translateY(-50%);
  }

  /* Ensure header layout is correct */
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    padding: 0 20px;
  }

  .logo {
    height: 40px;
  }
}

/* Add this to ensure admin dashboard takes full height */
#app:has(> .admin-dashboard) {
  height: 100vh;
  overflow: hidden;
}

@media (max-width: 768px) {
  .fixed-container .white-paper-banner {
    display: none;
  }

  .fixed-container.has-banner {
    padding-top: 0 !important;
  }

  .route-container.has-banner {
    padding-top: 60px !important; /* Just the header height for mobile */
  }
}

.manager-dashboard-page {
  padding-top: 0 !important;
}
</style>
