<template>
  <div class="get-started-container">
    <div class="content-wrapper">
      <!-- Left Column: Main Content -->
      <div class="main-content">
        <!-- Step 1: Quiz -->
        <div v-if="currentStep === 1" class="step-content quiz-section">
          <h2>Let's understand your AI needs</h2>
          <div class="quiz-container">
            <div class="question-card">
              <h3>{{ questions[currentQuestionIndex].text }}</h3>
              <div class="options">
                <label 
                  v-for="(option, optIndex) in questions[currentQuestionIndex].options" 
                  :key="optIndex" 
                  class="option"
                  :class="{ selected: answers[currentQuestionIndex] === option }"
                >
                  <input 
                    type="radio" 
                    :name="'question' + currentQuestionIndex"
                    :value="option"
                    v-model="answers[currentQuestionIndex]"
                  >
                  <span class="option-text">{{ option }}</span>
                </label>
              </div>
            </div>
          </div>
          <div class="navigation-buttons">
            <button 
              v-if="currentQuestionIndex > 0"
              class="back-button" 
              @click="previousQuestion"
            >
              Back
            </button>
            <button 
              class="next-button" 
              :disabled="!answers[currentQuestionIndex]"
              @click="handleQuizNavigation"
            >
              {{ isLastQuestion ? 'Continue' : 'Next' }}
            </button>
          </div>
        </div>

        <!-- Step 2: About Victory AI -->
        <div v-if="currentStep === 2" class="step-content about-section">
          <h2>Welcome to Victory AI</h2>
          <p class="welcome-subtitle">Your partner in AI transformation</p>
          <div class="info-cards">
            <div class="info-card">
              <div class="card-icon">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M9 20H7C5.89543 20 5 19.1046 5 18V6C5 4.89543 5.89543 4 7 4H17C18.1046 4 19 4.89543 19 6V18C19 19.1046 18.1046 20 17 20H15M9 20V14C9 13.4477 9.44772 13 10 13H14C14.5523 13 15 13.4477 15 14V20M9 20H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M9 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                  <path d="M9 11H15" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                </svg>
              </div>
              <h3>Deep Discovery Process</h3>
              <p>We conduct thorough business analysis to identify the AI solutions that will create the most significant impact for your specific needs.</p>
            </div>
            <div class="info-card">
              <div class="card-icon">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M12 16L17 12L12 8M7 12H17" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12 3C16.9706 3 21 7.02944 21 12C21 16.9706 16.9706 21 12 21C7.02944 21 3 16.9706 3 12C3 7.02944 7.02944 3 12 3Z" stroke="currentColor" stroke-width="2"/>
                </svg>
              </div>
              <h3>Custom AI Solutions</h3>
              <p>Victory AI develops a personalized AI strategy and implementation plan designed specifically for your business goals.</p>
            </div>
            <div class="info-card">
              <div class="card-icon">
                <svg width="48" height="48" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M17 18C17 16.3431 14.7614 15 12 15C9.23858 15 7 16.3431 7 18" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                  <path d="M12 12C13.6569 12 15 10.6569 15 9C15 7.34315 13.6569 6 12 6C10.3431 6 9 7.34315 9 9C9 10.6569 10.3431 12 12 12Z" stroke="currentColor" stroke-width="2" stroke-linecap="round"/>
                  <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="currentColor" stroke-width="2"/>
                </svg>
              </div>
              <h3>Flexible Engagement</h3>
              <p>Choose the partnership that works for you: discovery only, full implementation, or ongoing strategic support.</p>
            </div>
          </div>
          <div class="button-container">
            <button class="next-button" @click="nextStep">Continue to Schedule Call</button>
          </div>
        </div>

        <!-- Step 3: Schedule Call -->
        <div v-if="currentStep === 3" class="step-content schedule-section">
          <div class="schedule-grid">
            <div class="schedule-info">
              <h2>Schedule a Call with Victory AI</h2>
              <p>Let's discuss how AI can help transform your business</p>
              <div class="schedule-benefits">
                <div class="benefit-item">
                  <span class="benefit-icon">⏱️</span>
                  <p>30-minute consultation</p>
                </div>
                <div class="benefit-item">
                  <span class="benefit-icon">💡</span>
                  <p>Personalized AI strategy discussion</p>
                </div>
                <div class="benefit-item">
                  <span class="benefit-icon">📊</span>
                  <p>Understand your business needs</p>
                </div>
              </div>
            </div>
            <div class="schedule-calendar">
              <CustomCalendar />
            </div>
            <!-- <div class="schedule-spacer"></div> -->
          </div>
        </div>
      </div>

      <!-- Right Column: Progress Steps -->
      <div class="progress-sidebar">
        <div class="progress-steps">
          <div 
            class="progress-step" 
            :class="{ 
              active: currentStep === 1,
              completed: currentStep > 1
            }"
          >
            <div class="step-indicator">
              <span class="step-number">1</span>
            </div>
            <div class="step-details">
              <h4>Quick Quiz</h4>
              <p>Tell us about your AI needs</p>
            </div>
          </div>
          <div 
            class="progress-step"
            :class="{ 
              active: currentStep === 2,
              completed: currentStep > 2
            }"
          >
            <div class="step-indicator">
              <span class="step-number">2</span>
            </div>
            <div class="step-details">
              <h4>About Victory AI</h4>
              <p>Learn how we can help</p>
            </div>
          </div>
          <div 
            class="progress-step"
            :class="{ active: currentStep === 3 }"
          >
            <div class="step-indicator">
              <span class="step-number">3</span>
            </div>
            <div class="step-details">
              <h4>Schedule Call</h4>
              <p>Book time with our team</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomCalendar from './CustomCalendar.vue'
import { mapActions } from 'vuex'

export default {
  name: 'GetStarted',
  components: {
    CustomCalendar
  },
  data() {
    return {
      currentStep: 1,
      currentQuestionIndex: 0,
      answers: [],
      questions: [
        {
          text: "What is your current level of AI implementation?",
          options: [
            "No AI implementation yet",
            "Chatbot use like ChatGPT/Claude",
            "Some automation with Low Code or No Code tools",
            "We've built custom AI solutions into our company"
          ]
        },
        {
          text: "What is your primary goal with AI implementation?",
          options: [
            "Automate repetitive business processes",
            "Reduce operational costs",
            "Enchance customer experience",
            "Help employees be more productive"
          ]
        },
        {
          text: "How soon are you looking to implement AI?",
          options: [
            "Immediately",
            "Within 3 months",
            "Within 6 months",
            "Still exploring"
          ]
        },
        {
          text: "What is your organization's size?",
          options: [
            "1-25 employees",
            "26-50 employees",
            "50-150 employees",
            "150+ employees"
          ]
        },
        {
          text: "What is your ideal target budget range?",
          options: [
            "$5,000 to $15,000",
            "$15,000 to $30,000",
            "$30,000 to $100,000",
            "$100,000 to $500,000"
          ]
        }
      ]
    }
  },
  computed: {
    isLastQuestion() {
      return this.currentQuestionIndex === this.questions.length - 1
    }
  },
  methods: {
    ...mapActions(['setSimpleQuizAnswers']),
    nextQuestion() {
      if (this.currentQuestionIndex < this.questions.length - 1) {
        this.currentQuestionIndex++
      }
    },
    previousQuestion() {
      if (this.currentQuestionIndex > 0) {
        this.currentQuestionIndex--
      }
    },
    handleQuizNavigation() {
      if (this.isLastQuestion) {
        this.saveQuizAnswers()
        this.nextStep()
      } else {
        this.nextQuestion()
      }
    },
    saveQuizAnswers() {
      const quizResults = this.questions.map((question, index) => ({
        question: question.text,
        answer: this.answers[index] || ''
      }))
      this.setSimpleQuizAnswers(quizResults)
    },
    nextStep() {
      if (this.currentStep < 3) {
        this.currentStep++
      }
    }
  }
}
</script>

<style scoped>
.get-started-container {
  width: 100%;
  min-height: calc(100vh - 160px); /* Adjust for header (80px) and banner (80px) */
  padding-top: 5rem; /* Updated to 5rem as requested */
  background: linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%);
}

.content-wrapper {
  max-width: 1400px;
  margin: 0 auto;
  padding: 2rem;
  display: grid;
  grid-template-columns: 1fr 300px;
  gap: 3rem;
  align-items: start;
}

.main-content {
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.progress-sidebar {
  position: sticky;
  top: calc(160px + 5rem); /* Adjusted to account for new top padding */
  background: white;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.progress-steps {
  display: flex;
  flex-direction: column;
  gap: 2rem;
}

.progress-step {
  display: flex;
  gap: 1rem;
  padding: 1rem;
  border-radius: 8px;
  transition: all 0.3s ease;
  position: relative;
}

.progress-step::before {
  content: '';
  position: absolute;
  left: 1.5rem;
  top: 100%;
  width: 2px;
  height: 2rem;
  background-color: #e0e0e0;
}

.progress-step:last-child::before {
  display: none;
}

.progress-step.active {
  background-color: rgba(var(--accent-victory-green-rgb), 0.05);
}

.progress-step.completed .step-indicator {
  background-color: var(--accent-victory-green);
  color: white;
}

.progress-step.completed::before {
  background-color: var(--accent-victory-green);
}

.step-indicator {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  border: 2px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  transition: all 0.3s ease;
}

.progress-step.active .step-indicator {
  border-color: var(--accent-victory-green);
  background-color: var(--accent-victory-green);
  color: white;
}

.step-details h4 {
  margin: 0;
  color: #333;
  font-size: 1rem;
}

.step-details p {
  margin: 0.25rem 0 0;
  color: #666;
  font-size: 0.875rem;
}

.quiz-container {
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
}

.question-card {
  background-color: white;
  border-radius: 12px;
  padding: 2rem;
}

.question-card h3 {
  margin-bottom: 2rem;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
}

.options {
  display: grid;
  gap: 1rem;
}

.option {
  display: flex;
  align-items: center;
  padding: 1rem;
  border: 2px solid #e0e0e0;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.option:hover {
  border-color: var(--accent-victory-green);
  background-color: rgba(var(--accent-victory-green-rgb), 0.05);
}

.option.selected {
  border-color: var(--accent-victory-green);
  background-color: rgba(var(--accent-victory-green-rgb), 0.1);
}

.option input[type="radio"] {
  margin-right: 1rem;
}

.navigation-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.back-button,
.next-button {
  padding: 0.75rem 2rem;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.back-button {
  background-color: white;
  border: 2px solid var(--accent-victory-green);
  color: var(--accent-victory-green);
}

.next-button {
  background-color: var(--accent-victory-green);
  border: none;
  color: white;
}

.next-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.back-button:hover {
  background-color: rgba(var(--accent-victory-green-rgb), 0.05);
}

.next-button:hover:not(:disabled) {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 128, 0, 0.2);
}

.about-section {
  text-align: center;
}

.welcome-subtitle {
  color: #666;
  font-size: 1.25rem;
  margin: 1rem 0 3rem;
  text-align: center;
  width: 100%;
  display: block;
  max-width: none !important; /* Override global p tag max-width */
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin: 2rem 0 3rem;
}

.info-card {
  background-color: white;
  padding: 2.5rem 2rem;
  border-radius: 12px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
  text-align: center;
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
}

.info-card:hover {
  transform: translateY(-4px);
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
}

.card-icon {
  width: 64px;
  height: 64px;
  margin: 0 auto 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(var(--accent-victory-green-rgb), 0.1);
  border-radius: 16px;
  color: var(--accent-victory-green);
}

.info-card h3 {
  color: var(--accent-victory-green);
  margin: 1rem 0;
  font-size: 1.25rem;
  font-weight: 600;
}

.info-card p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}

.next-button {
  min-width: 240px;
}

.schedule-button {
  display: inline-block;
  padding: 1rem 2rem;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  transition: all 0.3s ease;
}

.schedule-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 128, 0, 0.2);
}

.schedule-section {
  padding: 0;
  height: 100%;
}

.schedule-grid {
  display: grid;
  grid-template-columns: minmax(300px, 1fr) minmax(600px, 2fr);
  gap: 0;
  margin: -2rem;
  height: 100%;
  min-height: 600px;
  max-height: calc(100vh - 240px);
  overflow: hidden;
}

.schedule-info {
  padding: 2rem;
  background: linear-gradient(135deg, rgba(var(--accent-victory-green-rgb), 0.1) 0%, rgba(var(--accent-victory-green-rgb), 0.05) 100%);
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  height: 100%;
}

.schedule-info h2 {
  color: var(--accent-victory-green);
  font-size: 2rem;
  margin: 0;
  line-height: 1.2;
}

.schedule-info p {
  color: #666;
  font-size: 1.125rem;
  line-height: 1.5;
  margin: 0;
}

.schedule-benefits {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
}

.benefit-item {
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0.5rem;
}

.benefit-icon {
  font-size: 1.25rem;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  flex-shrink: 0;
}

.benefit-item p {
  font-size: 0.95rem;
  margin: 0;
  line-height: 1.4;
}

.schedule-calendar {
  padding: 2rem 3rem;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background: white;
  height: 100%;
  overflow-y: auto;
}

.schedule-spacer {
  background: linear-gradient(135deg, rgba(var(--accent-victory-green-rgb), 0.05) 0%, rgba(var(--accent-victory-green-rgb), 0.02) 100%);
  height: 100%;
}

@media (max-width: 1200px) {
  .schedule-grid {
    grid-template-columns: minmax(300px, 1fr) minmax(500px, 2fr);
  }
}

@media (max-width: 768px) {
  .get-started-container {
    padding-top: 1rem;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }

  .quiz-section h2 {
    text-align: center;
    margin-bottom: 1rem;
  }

  .content-wrapper {
    flex: 1;
    display: grid;
    grid-template-columns: 1fr;
    padding: 1rem;
    gap: 1rem;
    margin: 0;
    height: calc(100vh - 160px);
  }

  .main-content {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow: hidden;
  }

  .step-content {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  /* Quiz Section Adjustments */
  .quiz-container {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .question-card {
    flex: 1;
    padding: 1rem;
    display: flex;
    flex-direction: column;
  }

  .question-card h3 {
    font-size: 1.125rem;
    margin-bottom: 1rem;
  }

  .options {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    justify-content: center;
  }

  .option {
    padding: 0.75rem;
  }

  .navigation-buttons {
    margin-top: 1rem;
    padding-bottom: 0.5rem;
  }

  /* About Section Adjustments */
  .welcome-subtitle {
    font-size: 0.875rem;
    margin: 0.25rem 0 0.75rem;
  }

  .info-cards {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    margin: 0.5rem 0;
    overflow-y: auto;
  }

  .info-card {
    padding: 0.75rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .card-icon {
    width: 40px;
    height: 40px;
    margin-bottom: 0.5rem;
  }

  .info-card h3 {
    font-size: 0.875rem;
    margin: 0.25rem 0;
    font-weight: 600;
  }

  .info-card p {
    font-size: 0.75rem;
    line-height: 1.3;
    margin: 0;
  }

  .button-container {
    margin-top: 1rem;
  }

  /* Schedule Section Adjustments */
  .schedule-grid {
    grid-template-columns: 1fr;
    height: 100%;
    margin: 0;
    gap: 1rem;
  }

  .schedule-info {
    padding: 1rem;
    gap: 0.75rem;
  }

  .schedule-info h2 {
    font-size: 1.25rem;
  }

  .schedule-info p {
    font-size: 0.875rem;
  }

  .schedule-benefits {
    margin-top: 0.75rem;
    gap: 0.5rem;
  }

  .benefit-item {
    padding: 0.25rem;
  }

  .benefit-icon {
    width: 32px;
    height: 32px;
    font-size: 1rem;
  }

  .schedule-calendar {
    padding: 1rem;
    height: auto;
  }

  /* Progress Steps Adjustments */
  .progress-sidebar {
    position: static;
    padding: 0.75rem;
    margin-bottom: 0.5rem;
  }

  .progress-steps {
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5rem;
  }

  .progress-step {
    flex-direction: column;
    align-items: center;
    padding: 0.5rem;
    flex: 1;
  }

  .progress-step::before {
    display: none;
  }

  .step-indicator {
    width: 28px;
    height: 28px;
    margin-bottom: 0.25rem;
  }

  .step-details {
    text-align: center;
  }

  .step-details h4 {
    font-size: 0.75rem;
    margin-bottom: 0.25rem;
  }

  .step-details p {
    display: none;
  }
}
</style> 