<template>
  <section class="hero" ref="heroSection" 
    @touchstart="handleTouchStart"
    @touchmove="handleTouchMove"
    @touchend="handleTouchEnd">
    <elevenlabs-convai v-if="isVisible && scriptLoaded && !isMobile" agent-id="fkflxGdoPUwbz5lv4v5T"></elevenlabs-convai>
    <div class="hero-content">
      <div class="hero-left">
    <img src="@/assets/logo.png" alt="Victory AI Logo" class="hero-logo">
    <div class="hero-text">
      <h1>Optimize Your Business Processes</h1>
      <h3>By building AI agents into your company</h3>
    </div>
    <div class="button-container">
          <button @click="$router.push('/get-started')" class="learn-more-btn">Get Started</button>
      <button @click="$router.push('/schedule')" class="schedule-btn">Schedule Call</button>
        </div>
      </div>
      <div class="hero-right">
        <transition name="fade" mode="out-in">
          <div v-if="!selectedDepartment" key="departments-grid">
            <h2 class="departments-title">Choose Your Department</h2>
            <div class="departments-grid">
              <button 
                v-for="dept in departments" 
                :key="dept.name"
                class="department-btn"
                @click="selectDepartment(dept)"
              >
                <span class="dept-name">{{ dept.name }}</span>
                <span class="dept-description">{{ dept.brief }}</span>
              </button>
            </div>
          </div>
          <div v-else key="department-details" class="department-details">
            <div class="details-header">
              <h2>{{ selectedDepartment.name }}</h2>
              <button class="back-btn" @click="selectedDepartment = null">
                Back <i class="fas fa-arrow-left"></i>
              </button>
            </div>
            <div class="details-content">
              <h3>Example AI Applications</h3>
              <ul>
                <li v-for="(benefit, index) in selectedDepartment.benefits" 
                    :key="index">
                  {{ benefit }}
                </li>
              </ul>
              <h3>Implementation Process</h3>
              <ol>
                <li v-for="(step, index) in selectedDepartment.process" 
                    :key="index">
                  {{ step }}
                </li>
              </ol>
              <div class="expected-outcomes">
                <h3>Example Results</h3>
                <p>{{ selectedDepartment.outcomes }}</p>
              </div>
              <button @click="$router.push('/schedule')" class="schedule-consultation-btn">
                Schedule Free Consultation
              </button>
            </div>
          </div>
        </transition>
      </div>
    </div>
    <div class="semicircle"></div>
  </section>
</template>

<script>
export default {
  name: 'HeroSection',
  computed: {
    isBannerVisible() {
      return !this.$store.state.whitePaperBannerClosed;
    }
  },
  watch: {
    isBannerVisible: {
      immediate: true,
      handler(isVisible) {
        if (isVisible) {
          document.documentElement.style.setProperty('--banner-offset', '-10vh');
        } else {
          document.documentElement.style.setProperty('--banner-offset', '0');
        }
      }
    }
  },
  beforeUnmount() {
    document.documentElement.style.removeProperty('--banner-offset');
  },
  data() {
    return {
      scriptLoaded: false,
      isVisible: false,
      observer: null,
      selectedDepartment: null,
      isMobile: window.innerWidth <= 768,
      departments: [
        {
          name: 'Inventory Management',
          brief: 'Optimize stock levels and supply chain',
          benefits: [
            'AI-powered demand forecasting and planning',
            'Smart reorder point optimization',
            'Automated supplier communication',
            'Real-time inventory optimization suggestions'
          ],
          process: [
            'Analysis of inventory data and patterns',
            'Custom AI model training on your demand history',
            'Integration with inventory management systems',
            'Team training on AI-driven inventory decisions'
          ],
          outcomes: 'Potential optimization results: Up to 50% reduction in stockout incidents, 40% decrease in excess inventory through AI forecasting, and approximately 35% improvement in inventory turnover rates.'
        },
        {
          name: 'Sales',
          brief: 'Boost revenue and conversion rates',
          benefits: [
            'AI-powered email and proposal writing',
            'Automated personalized outreach at scale',
            'Smart lead qualification and prioritization',
            'Real-time conversation insights and coaching'
          ],
          process: [
            'Analysis of current sales communications and processes',
            'Custom AI model training on your successful sales data',
            'Integration with CRM and communication tools',
            'Team training on AI-enhanced workflows'
          ],
          outcomes: 'Example improvements could include: 35% faster deal closure through automated follow-ups, 40% increase in response rates with AI-optimized messaging, and 25% higher conversion rates using intelligent lead prioritization.'
        },
        {
          name: 'Customer Service',
          brief: 'Enhance customer satisfaction',
          benefits: [
            'AI-powered response generation and suggestions',
            'Intelligent ticket classification and routing',
            'Automated response drafting for common issues',
            'Real-time sentiment analysis and escalation'
          ],
          process: [
            'Analysis of support tickets and common issues',
            'Custom AI training on your best service interactions',
            'Integration with support and communication platforms',
            'Team training on AI-assisted support workflows'
          ],
          outcomes: 'Potential improvements include: 60% reduction in response time using AI-assisted replies, 45% faster resolution times through intelligent routing, and up to 35% higher customer satisfaction scores with consistent, quality responses.'
        },
        {
          name: 'Operations',
          brief: 'Streamline business processes',
          benefits: [
            'AI-powered process optimization and automation',
            'Smart workflow suggestions and improvements',
            'Automated documentation and reporting',
            'Real-time efficiency monitoring and alerts'
          ],
          process: [
            'Analysis of current operational workflows',
            'Custom AI model training on your processes',
            'Integration with operational systems',
            'Team training on AI-optimized workflows'
          ],
          outcomes: 'Example efficiency gains: 40% reduction in manual task time through automation, 35% improvement in process efficiency with AI optimization, and potentially 30% fewer operational errors using AI monitoring.'
        },
        {
          name: 'Finance',
          brief: 'Improve financial efficiency',
          benefits: [
            'AI-powered financial analysis and reporting',
            'Automated anomaly detection and fraud prevention',
            'Smart cash flow forecasting and optimization',
            'Real-time financial insights and recommendations'
          ],
          process: [
            'Analysis of financial data and reporting needs',
            'Custom AI model training on your financial patterns',
            'Integration with financial systems and tools',
            'Team training on AI-enhanced financial workflows'
          ],
          outcomes: 'Potential financial improvements: Up to 65% faster reporting cycles with automation, estimated 70% better fraud detection rates using AI monitoring, and approximately 45% more accurate cash flow forecasting.'
        },
        {
          name: 'Marketing',
          brief: 'Maximize marketing ROI',
          benefits: [
            'AI-powered content creation and optimization',
            'Automated multi-channel campaign generation',
            'Smart audience segmentation and targeting',
            'Real-time campaign performance optimization'
          ],
          process: [
            'Analysis of current marketing content and performance',
            'Custom AI model training on successful campaigns',
            'Integration with marketing platforms and tools',
            'Team training on AI-enhanced content creation'
          ],
          outcomes: 'Potential benefits include: 40% faster content creation with AI assistance, 35% higher engagement rates through optimized messaging, and approximately 30% improvement in campaign ROI using AI-driven insights.'
        }
      ],
      touchStartY: 0,
      touchEndY: 0
    }
  },
  methods: {
    loadScript() {
      if (!document.getElementById('elevenlabs-convai-script')) {
        const script = document.createElement('script')
        script.src = 'https://elevenlabs.io/convai-widget/index.js'
        script.async = true
        script.id = 'elevenlabs-convai-script'
        script.onload = () => {
          this.scriptLoaded = true
        }
        document.head.appendChild(script)
      } else {
        this.scriptLoaded = true
      }
    },
    removeScript() {
      const script = document.getElementById('elevenlabs-convai-script')
      if (script) {
        document.head.removeChild(script)
        this.scriptLoaded = false
      }
    },
    scrollToChallenge() {
      this.$emit('navigate-to-challenge');
    },
    setupIntersectionObserver() {
      this.observer = new IntersectionObserver((entries) => {
        const isIntersecting = entries[0].isIntersecting
        if (isIntersecting && !this.scriptLoaded) {
          this.loadScript()
        }
        this.isVisible = isIntersecting
      }, {
        threshold: 0.1
      })
      
      this.observer.observe(this.$refs.heroSection)
    },
    selectDepartment(dept) {
      this.selectedDepartment = dept;
    },
    checkMobile() {
      this.isMobile = window.innerWidth <= 768;
    },
    handleTouchStart(e) {
      this.touchStartY = e.touches[0].clientY;
    },
    handleTouchMove(e) {
      e.preventDefault(); // Prevent default scrolling
      this.touchEndY = e.touches[0].clientY;
    },
    handleTouchEnd() {
      const swipeDistance = this.touchEndY - this.touchStartY;
      const threshold = 50;

      if (Math.abs(swipeDistance) >= threshold) {
        const heroContent = this.$refs.heroSection.querySelector('.hero-content');
        if (swipeDistance > 0) {
          // Swiping down - go to top
          heroContent.style.transform = 'translateY(0)';
        } else {
          // Swiping up - go to bottom
          heroContent.style.transform = 'translateY(-100vh)';
        }
      }
    }
  },
  mounted() {
    this.setupIntersectionObserver()
    window.addEventListener('resize', this.checkMobile)
  },
  beforeDestroy() {
    if (this.observer) {
      this.observer.disconnect()
    }
    if (!document.querySelector('.hero')) {
      this.removeScript()
    }
    window.removeEventListener('resize', this.checkMobile)
  }
}
</script>

<style scoped>
.hero {
  scroll-snap-align: start;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  margin-top: var(--banner-offset, 0);
  transition: margin-top 0.5s cubic-bezier(0.4, 0, 0.2, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-content {
  height: auto;
  display: flex;
  padding: 0 10% 0 15%;
  gap: 4rem;
  align-items: center;
  margin-bottom: 50px;
}

.hero-left {
  flex: 1;
  max-width: 800px;
}

.hero-right {
  flex: 1;
  max-width: 600px;
  max-height: 80vh;
  overflow: hidden;
}

.departments-title {
  font-size: 1.8rem;
  color: var(--accent-victory-green);
  margin-bottom: 1.5rem;
  text-align: center;
}

.departments-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  padding: 1rem;
}

.department-btn {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border: 2px solid var(--accent-victory-green);
  border-radius: 12px;
  padding: 1.5rem;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.department-btn:hover {
  transform: translateY(-5px);
  background: var(--accent-victory-green);
}

.dept-name {
  font-size: 1.2rem;
  font-weight: bold;
  color: var(--accent-victory-green);
}

.department-btn:hover .dept-name {
  color: var(--primary-background-cream);
}

.dept-description {
  font-size: 0.9rem;
  color: var(--main-text-color);
  opacity: 0.8;
}

.department-btn:hover .dept-description {
  color: var(--primary-background-cream);
  opacity: 1;
}

.hero-logo {
  width: 400px;
  height: auto;
  margin-bottom: 2rem;
  transition: all 0.5s ease;
}

.hero-text {
  margin-bottom: 2rem;
  line-height: 1.2;
  max-width: 800px;
}

h1, h2, h3 {
  margin: 12px;
}

h1 {
  font-size: 3rem;
  color: var(--accent-victory-green);
}

h2 {
  font-size: 2.5rem;
  color: var(--main-text-color);
}

h3 {
  font-size: 2rem;
  color: var(--accent-victory-green);
  font-weight: normal;
}

.button-container {
  display: flex;
  gap: 20px;
  margin-top: 20px;
}

.learn-more-btn, .schedule-btn {
  border: none;
  border-radius: 25px;
  padding: 12px 24px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.learn-more-btn {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.schedule-btn {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.learn-more-btn:hover, .schedule-btn:hover {
  transform: scale(1.05);
}

.learn-more-btn:hover {
  background-color: var(--accent-victory-green);
  color: var(--primary-background-cream);
}

.schedule-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

.semicircle {
  position: absolute;
  bottom: -50px;
  left: 50%;
  transform: translateX(-50%);
  width: 100vw;
  height: 100px;
  background-color: var(--accent-sunny-yellow);
  border-top-left-radius: 100px;
  border-top-right-radius: 100px;
  z-index: 10;
}

@media (max-width: 1200px) {
  .hero-content {
    flex-direction: column;
    padding: 2rem 10%;
    gap: 2rem;
    justify-content: center;
  }

  .hero-left, .hero-right {
    max-width: 100%;
  }

  .departments-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh;
    overflow-y: hidden;
    padding-top: 0;
  }

  .hero-content {
    flex-direction: column;
    padding: 0 5%;
    height: 200vh;
    transform: translateY(0);
    transition: transform 0.3s ease;
  }

  .hero-left, .hero-right {
    height: 100vh;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding-top: 0;
    margin-top: -5vh;
  }

  .hero-logo {
    width: 280px;
    margin-bottom: 1.5rem;
  }

  .hero-text {
    margin-bottom: 1.5rem;
  }

  h1 {
    font-size: 2.5rem;
  }

  h3 {
    font-size: 1.5rem;
  }

  .departments-grid {
    grid-template-columns: 1fr;
  }

  .departments-title {
    font-size: 1.2rem;
    margin-bottom: 0.75rem;
  }

  .departments-grid {
    grid-template-columns: 1fr;
    gap: 0.75rem;
    padding: 0.5rem;
  }

  .department-btn {
    padding: 1rem;
    gap: 0.25rem;
  }

  .dept-name {
    font-size: 0.9rem;
  }

  .dept-description {
    font-size: 0.75rem;
    line-height: 1.2;
  }

  .department-details {
    padding: 0;
    max-height: none;
  }

  .details-header h2 {
    font-size: 1.2rem;
  }

  .details-content h3 {
    font-size: 0.9rem;
  }

  .details-content ul li,
  .details-content ol li {
    font-size: 0.7rem;
    line-height: 1.2;
    margin-bottom: 0.3rem;
  }

  .expected-outcomes p {
    font-size: 0.7rem;
    line-height: 1.2;
  }

  .schedule-consultation-btn {
    padding: 0.6rem;
    font-size: 0.9rem;
    margin-top: 0.75rem;
  }
}

.department-details {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0.5rem;
  max-height: 80vh;
}

.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.5rem;
}

.details-header h2 {
  font-size: 1.8rem;
  margin: 0;
  order: -1;
}

.back-btn {
  background: none;
  border: none;
  color: var(--accent-victory-green);
  font-size: 0.9rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: all 0.3s ease;
}

.back-btn i {
  order: 1;
}

.details-content {
  flex: 1;
  overflow-y: auto;
  padding-right: 0.5rem;
}

.details-content h3 {
  color: var(--accent-victory-green);
  margin: 1rem 0 0.5rem;
  font-size: 1.1rem;
}

.details-content ul,
.details-content ol {
  margin-left: 1.2rem;
  margin-bottom: 1rem;
  padding: 0;
}

.details-content li {
  margin-bottom: 0.5rem;
  line-height: 1.3;
  font-size: 0.95rem;
}

.expected-outcomes {
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  border: 1px solid var(--accent-victory-green);
}

.expected-outcomes h3 {
  margin-top: 0;
}

.expected-outcomes p {
  font-size: 0.95rem;
  line-height: 1.4;
  margin: 0;
}

.schedule-consultation-btn {
  width: 100%;
  background-color: var(--accent-victory-green);
  color: white;
  border: none;
  padding: 0.8rem;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 0.5rem;
}

.schedule-consultation-btn:hover {
  background-color: var(--accent-sunny-yellow);
  color: var(--accent-victory-green);
}

/* Fade transition */
.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateX(20px);
}

/* Scrollbar styling */
.details-content::-webkit-scrollbar {
  width: 6px;
}

.details-content::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1);
  border-radius: 3px;
}

.details-content::-webkit-scrollbar-thumb {
  background: var(--accent-victory-green);
  border-radius: 3px;
}

.details-content::-webkit-scrollbar-thumb:hover {
  background: var(--accent-sunny-yellow);
}
</style>
