<template>
  <div class="home" ref="homeContainer">
    <HeroSection ref="heroSection" :style="{ opacity: heroOpacity }" @navigate-to-challenge="scrollToChallenge" />
    <!-- <ChallengeSection 
      ref="challengeSection" 
      @challenge-completed="handleChallengeCompleted"
      @challenge-scroll-top="handleChallengeScrollTop"
    />
    <Waste ref="wasteSection" />
    <ChallengeTwoSection 
      ref="challengeTwoSection" 
      @challenge-two-completed="handleChallengeTwoCompleted"
      @challenge-two-scroll-top="handleChallengeTwoScrollTop"
    />
    <Plan ref="planSection" />
    <PlanTwo ref="planTwoSection" />
    <HowSection ref="howSection" />
    <AuthoritySection 
      ref="authoritySection" 
      @navigate-next="navigateToNextSection"
      @section-scroll="handleSectionScroll"
      @authority-completed="handleAuthorityCompleted"
      @authority-scroll-top="handleAuthorityScrollTop"
    />
    <CTASection ref="ctaSection" @navigate-to-hero="$emit('navigate-to-hero')" /> -->
    <!-- <div class="cta-buttons">
      <router-link to="/schedule" class="btn btn-primary">Schedule Call</router-link>

    </div> -->
  </div>
</template>

<script>
import HeroSection from '@/components/HeroSection.vue'
import ChallengeSection from '@/components/ChallengeSection.vue'
import Waste from '@/components/Waste.vue'
import ChallengeTwoSection from '@/components/ChallengeTwoSection.vue'
import AuthoritySection from '@/components/AuthoritySection.vue'
import HowSection from '@/components/HowSection.vue'
import CTASection from '@/components/CTASection.vue'
import Plan from '@/components/Plan.vue'
import PlanTwo from '@/components/PlanTwo.vue'

export default {
  name: 'HomeView',
  components: {
    HeroSection,
    ChallengeSection,
    Waste,
    ChallengeTwoSection,
    AuthoritySection,
    HowSection,
    CTASection,
    Plan,
    PlanTwo
  },
  emits: ['update-hero-view', 'update-cta-view', 'update-scroll-progress', 'navigate-to-hero'],
  data() {
    return {
      sections: [
        'heroSection', 
        'challengeSection', 
        'wasteSection', 
        'challengeTwoSection', 
        'planSection', 
        'planTwoSection', 
        'howSection',
        'authoritySection', 
        'ctaSection'
      ],
      currentSectionIndex: 0,
      isScrolling: false,
      scrollTimeout: null,
      scrollThreshold: 20,
      accumulatedScroll: 0,
      scrollProgress: 0,
      isAuthorityActive: false,
      lastScrollTime: 0,
      heroOpacity: 1,
      isChallengeCompleted: false,
      isChallengeActive: false,
      isChallengeTwoActive: false,
      isChallengeLocked: false,
      isChallengeTwoLocked: false,
      isScrollingLocked: false,
      isTransitioning: false,
      isPlanActive: false,
      isPlanTwoActive: false,
      isHowActive: false,
      isQuizActive: false,
      scrollDuration: 500,
      scrollCooldown: 100,
      challengeCompleted: false,
      authorityCompleted: false,
    }
  },
  mounted() {
    // window.addEventListener('wheel', this.handleWheel, { passive: false })
    // window.addEventListener('scroll', this.handleScroll)
    // this.updateHeaderState(0)
    // this.logCurrentSection()
    // this.$refs.homeContainer.addEventListener('scroll', this.handleHomeScroll)
    // this.setupSectionObserver()

    // if (window.innerWidth <= 768) {
    //   window.addEventListener('wheel', this.handleMobileScroll, { passive: false });
    // }

    // Add touch event listeners for mobile
    // if (window.innerWidth <= 768) {
    //   this.$el.addEventListener('touchstart', this.handleTouchStart, { passive: false });
    //   this.$el.addEventListener('touchmove', this.handleTouchMove, { passive: false });
    //   this.$el.addEventListener('touchend', this.handleTouchEnd, { passive: false });
    // }
  },
  beforeUnmount() {
    // window.removeEventListener('wheel', this.handleWheel)
    // window.removeEventListener('scroll', this.handleScroll)
    // this.$refs.homeContainer.removeEventListener('scroll', this.handleHomeScroll)
    // this.$store.dispatch('updateCurrentSection', null);

    // if (window.innerWidth <= 768) {
    //   window.removeEventListener('wheel', this.handleMobileScroll);
    // }

    // Clean up touch event listeners
    // if (window.innerWidth <= 768) {
    //   this.$el.removeEventListener('touchstart', this.handleTouchStart);
    //   this.$el.removeEventListener('touchmove', this.handleTouchMove);
    //   this.$el.removeEventListener('touchend', this.handleTouchEnd);
    // }
  },
  methods: {
    handleWheel(event) {
      event.preventDefault();
      
      if (this.isScrollingLocked || this.isTransitioning) {
        return;
      }

      const currentTime = new Date().getTime();
      if (currentTime - this.lastScrollTime < this.scrollCooldown) {
        return;
      }

      this.accumulatedScroll += event.deltaY;

      if (Math.abs(this.accumulatedScroll) >= this.scrollThreshold) {
        const direction = this.accumulatedScroll > 0 ? 1 : -1;

        if (this.currentSectionIndex === 1) { // Challenge section
          this.handleChallengeScroll(direction);
        } else if (this.currentSectionIndex === 7) { // Authority section
          const handled = this.$refs.authoritySection.handleScroll(event);
          if (!handled) {
            this.scrollToSection(direction);
          }
        } else {
          this.scrollToSection(direction);
        }

        this.accumulatedScroll = 0;
        this.lastScrollTime = currentTime;
      }
    },
    handleChallengeScroll(direction) {
      if (direction > 0) {
        if (this.challengeSlideIndex < this.challengeSlideCount - 1) {
          this.challengeSlideIndex++;
        } else {
          this.scrollToSection(1);
        }
      } else {
        if (this.challengeSlideIndex > 0) {
          this.challengeSlideIndex--;
        } else {
          this.scrollToSection(-1);
        }
      }
    },
    handleScroll() {
      const scrollPosition = window.pageYOffset
      const heroHeight = this.$refs.heroSection.$el.offsetHeight
      
      if (scrollPosition <= heroHeight) {
        this.heroOpacity = 1 - (scrollPosition / heroHeight)
      } else {
        this.heroOpacity = 0
      }
      
      this.updateScrollProgress(scrollPosition)
      
      // Handle parallax effect for ChallengeTwoSection
      if (this.currentSectionIndex === 2) {
        this.$refs.challengeTwoSection.handleParallax(scrollPosition)
      }
    },
    scrollToSection(direction) {
      if (this.isScrolling || this.isTransitioning) return;
      
      this.isScrolling = true;
      this.isTransitioning = true;
      
      const nextIndex = Math.max(0, Math.min(this.sections.length - 1, this.currentSectionIndex + direction));
      
      // Only apply the challenge-two lock on mobile
      if (window.innerWidth <= 768 && 
          this.currentSectionIndex === 3 && 
          direction > 0 && 
          !this.challengeTwoCompleted) {
        this.isScrolling = false;
        this.isTransitioning = false;
        return;
      }
      
      this.currentSectionIndex = nextIndex;
      this.updateHeaderState(nextIndex);
      
      // Reset the scroll position
      if (this.$refs[this.sections[nextIndex]]) {
        this.$refs[this.sections[nextIndex]].$el.scrollIntoView({
          behavior: 'smooth'
        });
      }
      
      setTimeout(() => {
        this.isScrolling = false;
        this.isTransitioning = false;
      }, this.scrollDuration);
    },
    updateHeaderState(index) {
      this.$emit('update-hero-view', index === 0)
      this.$emit('update-cta-view', index === this.sections.length - 1)
      this.isAuthorityActive = index === 7;
      if (this.isAuthorityActive) {
        // console.log('Activating AuthoritySection');
        this.$refs.authoritySection.setActive(true);
        this.authorityCompleted = false; // Reset when entering the section
      } else {
        this.$refs.authoritySection.setActive(false);
      }
      this.isChallengeActive = index === 1
      this.isChallengeTwoActive = index === 3
      this.isPlanActive = index === 4
      this.isPlanTwoActive = index === 5
      this.isHowActive = index === 6
      this.isQuizActive = index === 7
    },
    updateScrollProgress(scrollPosition) {
      const totalHeight = document.documentElement.scrollHeight - window.innerHeight
      const heroHeight = this.$refs.heroSection.$el.offsetHeight
      const ctaHeight = this.$refs.ctaSection.$el.offsetHeight

      if (scrollPosition <= heroHeight) {
        this.scrollProgress = Math.min(scrollPosition / heroHeight, 1)
      } else if (scrollPosition >= totalHeight - ctaHeight) {
        const ctaProgress = (scrollPosition - (totalHeight - ctaHeight)) / ctaHeight
        this.scrollProgress = Math.max(0, 1 - ctaProgress)
      } else {
        this.scrollProgress = 0
      }

      this.$emit('update-scroll-progress', this.scrollProgress)
    },
    navigateToNextSection() {
      this.isAuthorityActive = false
      const nextIndex = this.currentSectionIndex + 1
      if (nextIndex < this.sections.length) {
        this.scrollToSection(1)
      }
    },
    handleSectionScroll(direction) {
      this.isAuthorityActive = false
      this.scrollToSection(direction)
    },
    scrollToHow() {
      const howSectionIndex = this.sections.indexOf('howSection')
      this.smoothScrollToSection(howSectionIndex)
    },
    smoothScrollToSection(targetIndex) {
      if (this.isScrolling) return

      this.isScrolling = true
      this.currentSectionIndex = targetIndex
      const targetElement = this.$refs[this.sections[targetIndex]].$el
      const targetPosition = targetElement.offsetTop

      const startPosition = window.pageYOffset
      const distance = targetPosition - startPosition
      const startTime = performance.now()

      const animateScroll = (currentTime) => {
        const elapsedTime = currentTime - startTime
        const progress = Math.min(elapsedTime / this.scrollDuration, 1)
        const easeProgress = this.easeInOutCubic(progress)
        
        window.scrollTo(0, startPosition + distance * easeProgress)
        
        if (progress < 1) {
          requestAnimationFrame(animateScroll)
        } else {
          this.updateHeaderState(targetIndex)
          this.logCurrentSection()
          this.isScrolling = false
        }
      }

      requestAnimationFrame(animateScroll)
    },
    logCurrentSection() {
      // console.log(`Current section: ${this.sections[this.currentSectionIndex]}`)
    },
    easeInOutCubic(t) {
      return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1
    },
    navigateToChallenge() {
      this.scrollToSection(1) // Use scrollToSection instead of smoothScrollToSection
    },
    handleChallengeCompleted() {
      this.challengeCompleted = true;
      this.scrollToSection(1);
    },
    handleChallengeScrollTop() {
      this.scrollToSection(-1);
    },
    handleChallengeTwoCompleted() {
      this.challengeTwoCompleted = true;
      this.scrollToSection(1);
    },
    handleChallengeTwoScrollTop() {
      this.scrollToSection(-1)
    },

    scrollToQuiz() {
      const quizSectionIndex = this.sections.indexOf('quizSection')
      if (quizSectionIndex !== -1) {
        const direction = quizSectionIndex - this.currentSectionIndex
        this.scrollToSection(direction)
        // console.log('Scrolling to Quiz section')
      } else {
        console.error('Quiz section not found')
      }
    },

    scrollToChallenge() {
      const challengeSectionIndex = this.sections.indexOf('challengeSection');
      if (challengeSectionIndex !== -1) {
        this.scrollToSection(challengeSectionIndex - this.currentSectionIndex);
      }
    },
    handleAuthorityCompleted() {
      this.authorityCompleted = true;
      this.scrollToSection(1);
    },
    handleAuthorityScrollTop() {
      this.scrollToSection(-1);
    },
    scrollToTop() {
      this.$refs.homeContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
      this.currentSectionIndex = 0;
      this.updateHeaderState(0);
    },
    handleHomeScroll() {
      const scrollPosition = this.$refs.homeContainer.scrollTop
      const heroHeight = this.$refs.heroSection.$el.offsetHeight

      // Close banner when scrolled past hero section
      if (scrollPosition > heroHeight * 0.5) {
        this.$store.dispatch('closeWhitePaperBanner')
      }
    },
    setupSectionObserver() {
      // Only set up observer for desktop
      if (window.innerWidth > 768) {
        const options = {
          threshold: 0.5
        }

        const observer = new IntersectionObserver(entries => {
          entries.forEach(entry => {
            if (entry.isIntersecting) {
              this.$store.dispatch('updateCurrentSection', entry.target.className)
            }
          })
        }, options)

        observer.observe(this.$refs.hero)
        observer.observe(this.$refs.cta)
      }
    },
    handleMobileScroll(event) {
      if (window.innerWidth <= 768) {
        event.preventDefault();
        
        // Determine scroll direction
        const direction = event.deltaY > 0 ? 1 : -1;
        
        // Get all sections
        const sections = this.$el.querySelectorAll('section');
        const currentIndex = Array.from(sections).findIndex(section => {
          const rect = section.getBoundingClientRect();
          return rect.top >= -100 && rect.top <= 100;
        });
        
        // Calculate target index
        const targetIndex = Math.max(0, Math.min(sections.length - 1, currentIndex + direction));
        
        // Scroll to target section
        sections[targetIndex].scrollIntoView({ behavior: 'smooth' });
      }
    },
    handleTouchStart(event) {
      if (window.innerWidth <= 768) {
        // Don't prevent default if touching a button, link or footer element
        if (event.target.closest('.action-button') || 
            event.target.closest('.btn') || 
            event.target.closest('.learn-more-btn') ||
            event.target.closest('.schedule-btn') ||
            event.target.closest('.footer-links a') ||  // Add footer links
            event.target.closest('.footer-section a')) {
          return;
        }
        
        this.touchStartY = event.touches[0].clientY;
        this.touchStartX = event.touches[0].clientX;
        
        // If we're in the challenge-two section, don't prevent default
        if (this.currentSectionIndex === 3) {
          return;
        }
        event.preventDefault();
      }
    },
    handleTouchMove(event) {
      if (window.innerWidth <= 768) {
        // Don't prevent default if touching a button, link or footer element
        if (event.target.closest('.action-button') || 
            event.target.closest('.btn') || 
            event.target.closest('.learn-more-btn') ||
            event.target.closest('.schedule-btn') ||
            event.target.closest('.footer-links a') ||  // Add footer links
            event.target.closest('.footer-section a')) {
          return;
        }
        
        // If we're in the challenge-two section, let it handle its own touch events
        if (this.currentSectionIndex === 3) {
          return;
        }
        event.preventDefault();
      }
    },
    handleTouchEnd(event) {
      if (window.innerWidth <= 768 && !this.isTouchScrolling) {
        // If we're in the challenge-two section, let it handle its own touch events
        if (this.currentSectionIndex === 3) {
          return;
        }

        // Add check for buttons, links and footer elements
        const target = event.target;
        if (target.closest('.action-button') || 
            target.closest('.btn') || 
            target.closest('.learn-more-btn') ||
            target.closest('.schedule-btn') ||
            target.closest('.footer-links a') ||  // Add footer links
            target.closest('.footer-section a')) {
          return; // Let the link/button click handler work
        }

        this.isTouchScrolling = true;
        
        const touchEndY = event.changedTouches[0].clientY;
        const direction = touchEndY < this.touchStartY ? 1 : -1;
        
        // Get all sections
        const sections = this.$el.querySelectorAll('section');
        const currentIndex = Array.from(sections).findIndex(section => {
          const rect = section.getBoundingClientRect();
          return rect.top >= -100 && rect.top <= 100;
        });
        
        // Calculate target index
        const targetIndex = Math.max(0, Math.min(sections.length - 1, currentIndex + direction));
        
        // Only scroll if we're not in the challenge-two section
        if (this.currentSectionIndex !== 3) {
          sections[targetIndex].scrollIntoView({ behavior: 'smooth' });
        }
        
        // Reset touch scrolling flag after animation
        setTimeout(() => {
          this.isTouchScrolling = false;
        }, 800);
      }
    }
  }
}
</script>

<style scoped>
.home {
  /* scroll-snap-type: y proximity; */
  /* overflow-y: scroll; */
  overflow: hidden;  /* Add this to prevent scrolling */
  /* height: 100vh; */
  background: linear-gradient(90deg, var(--primary-background-cream), var(--secondary-background-sky-blue));
  /* scroll-behavior: smooth; */
}


@media (max-width: 768px) {
  .home > * {
    height: auto;  /* Allow sections to be their natural height */
    min-height: 100vh;  /* Ensure minimum full viewport height */
  }

  .home {
    overflow-x: hidden;
    /* scroll-snap-type: y mandatory; */ /* Ensure better mobile scrolling */
  }
}
</style>
